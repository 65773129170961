var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAttributes = void 0;

function setAttributes(element, // eslint-disable-next-line @typescript-eslint/no-explicit-any
attributes) {
  for (var key in attributes) {
    if (attributes.hasOwnProperty(key)) {
      var value = attributes[key];

      if (value == null) {
        element.removeAttribute(key);
      } else {
        element.setAttribute(key, value);
      }
    }
  }
}

exports.setAttributes = setAttributes;
export default exports;